@import url("https://fonts.googleapis.com/css2?family=Chelsea+Market&family=Fira+Sans:wght@400;500&family=Irish+Grover&family=Lobster&family=Oxygen&family=Poppins:wght@300&family=Readex+Pro&family=Roboto&family=Roboto+Mono&family=Rowdies:wght@300&family=Source+Sans+Pro&family=Teko:wght@300&family=Zen+Old+Mincho&display=swap");

body {
  width: 100%;
  margin: 0;
  /* background-color: #c7d2fe; */
  /* background-image: url("https://coolbackgrounds.io/images/backgrounds/index/ranger-4df6c1b6.png"); */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
  background-size: cover;
  background-color: #244f58;
  height: 100%;
  overflow: scroll;
}
